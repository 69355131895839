export const links = [
    {
        "nombre" : "Tramites y Servicios",
        "link" : "/transparencia",
        "submenu" : [
            {
                "subMenuNombre" : "Adquisición de la Tarjeta de Adulto Mayor",
                "subMenuUrl" : "https://ruts.hidalgo.gob.mx/ver/1504"
            },
            {
                "subMenuNombre" : "Adquisición de la Tarjeta de Discapacidad",
                "subMenuUrl" : "https://ruts.hidalgo.gob.mx/ver/4856"
            },
            {
                "subMenuNombre" : "Expedicion de Tarjetón para conductores",
                "subMenuUrl" : "https://ruts.hidalgo.gob.mx/ver/1506"
            },
            {
                "subMenuNombre" : "Quejas y denuncias contra personal operativo, de limpieza, vigilancia y tecnología",
                "subMenuUrl" : "https://ruts.hidalgo.gob.mx/ver/1502"
            },
            {
                "subMenuNombre" : "Reposición de la Tarjeta Preferente Tuzobús por robo o extravío.",
                "subMenuUrl" : "https://ruts.hidalgo.gob.mx/ver/1505"
            },
            {
                "subMenuNombre" : "Sugerencias y reportes contra personal Operativo, Limpieza, Vigilancia y tecnologia.",
                "subMenuUrl" : "https://ruts.hidalgo.gob.mx/ver/1503"
            }
        ]
    },
    {
        "nombre" : "Transparencia",
        "link" : "/transparencia",
        "submenu" : [
            {
                "subMenuNombre" : "48 Rubros",
                "subMenuUrl" : "/transparencia/Rubros"
            },
            {
                "subMenuNombre" : "Rubros Anteriores",
                "subMenuUrl" : "/transparencia/Rubros/RubrosHistoricos"
            },
            {
                "subMenuNombre" : "Armonización Contable",
                "subMenuUrl" : "/Armonizacion"
            },
            {
                "subMenuNombre" : "Licitaciones",
                "subMenuUrl" : "/Licitaciones"
            },
            {
                "subMenuNombre" : "Código de Ética",
                "subMenuUrl" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/1-INFORMACION-CONTABLE/Código_de_Ética.pdf"
            },
            {
                "subMenuNombre" : "Programa Anual de Mejora Regulatoria 2025",
                "subMenuUrl" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/Transparencia/Programa_Anual_de_Mejora_Regulatoria_2025.PDF"
            },
            {
                "subMenuNombre" : "Código de Conducta",
                "subMenuUrl" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/1-INFORMACION-CONTABLE/Código_de_Conducta_del_SITMAH.pdf"
            }
        ]
    },
    {
        "nombre" : "Nosotros",
        "link" : "/Nosotros",
       
    },
    {
        "nombre" : "Programa Institucional",
        "link" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/1-INFORMACION-CONTABLE/PID-SITMAH-2023-2028.pdf",
       
    }
   
]