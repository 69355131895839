export const Armonizacion = [
    //1.-informacion_contable
    {
        "titulo": "1.-INFORMACIÓN CONTABLE",
        "subtitulo": [
            {
                "informacionTitulo": "1.1 Estado de Situación Financiera"
            },
            {
                "informacionTitulo": "1.2 Estado de Actividades"
            },
            {
                "informacionTitulo": "1.3 Estado de Variación en la Hacienda Pública"
            },
            {
                "informacionTitulo": "1.4 Estado de Cambios en la Situación Financiera"
            },
            {
                "informacionTitulo": "1.5 Notas a los Estados Financieros"
            },
            {
                "informacionTitulo": "1.6 Estado Analitico del Activo"
            },
            {
                "informacionTitulo": "1.7 Estado de Flujos de Efectivo"
            },
            {
                "informacionTitulo": "1.8 Estado Analítico de la Deuda y Otros Pasivos"
            },
            {
                "informacionTitulo": "1.9 Informe de Pasivos Contingentes"
            },
            {
                "informacionTitulo": "1.10 Conciliación Entre los Ingresos Presupuestarios y Contables"
            },
            {
                "informacionTitulo": "1.11 CONCILIACION ENTRE LOS EGRESOS PRESUPUESTARIOS Y LOS GASTOS CONTABLES"
            },
            {
                "informacionTitulo": "1.12 ESTADO ANALITICO DEL PASIVO"
            }
        ]

    },
    //2.-informacion_presupuestal    
    {
        "titulo": "2.-INFORMACIÓN PRESUPUESTAL",
        "subtitulo": [
            {
                "informacionTitulo": "2.1 Estado Analítico de Ingresos"
            },
            {
                "informacionTitulo": "2.2 Estado Analítico del Ejercicio del Presupuesto de Egresos C.O.G"
            },
            {
                "informacionTitulo": "2.3 Estado Analítico del Ejercicio del Presupuesto de Egresos C.E"
            },
            {
                "informacionTitulo": "2.4 Estado Analítico del Ejercicio del Presupuesto de Egresos C.F"
            },
            {
                "informacionTitulo": "2.5 Estado Analítico del Ejercicio del Presupuesto de Egresos C.A"
            },
            {
                "informacionTitulo": "2.6 Endeudamiento Neto"
            },
            {
                "informacionTitulo": "2.7 Interes de la Deuda"
            },
            {
                "informacionTitulo": "2.8 Indicadores de Postura Fiscal"
            },
            {
                "informacionTitulo": "2.9 ESTADO ANALITICO DE INGRESOS PRESUPUESTALES"
            }
        ]

    },
    //3.-informacion_programatica
    {
        "titulo": "3.- INFORMACIÓN PROGRAMÁTICA",
        "subtitulo": [
            {
                "informacionTitulo": "3.1 Gasto por Categoria Programática"
            },
            {
                "informacionTitulo": "3.2 Programas y Proyectos de Inversión"
            },
            {
                "informacionTitulo": "3.3 Indicadores de Resultados"
            },
            {
                "informacionTitulo": "3.4 EVALUACIÓN DE DESEMPEÑO DE PROGRAMAS Y POLÍTICAS PUBlLICAS"
            },
            {
                "informacionTitulo": "3.5 METODOLOGÍAS E INDICADORES DE DESEMPEÑO"
            },
            {
                "informacionTitulo": "3.6 PROGRAMAS ANUAL DE EVALUACIONES"
            }
        ]

    },
    //4.-inventario_bienes    
    {
        "titulo": "4.- INVENTARIO DE BIENES",
        "subtitulo": [
            {
                "informacionTitulo": "4.1 Muebles"
            },
            {
                "informacionTitulo": "4.2 Inmuebles"
            }
        ]

    },
    //5.-cuenta_pública
    {
        "titulo": "5.- CUENTA PÚBLICA",
        "subtitulo": [
            {
                "informacionTitulo": "5.1 CUENTA PÚBLICA"
            },
            {
                "informacionTitulo": "5.2 DICTAMEN AUDITORIA EXTERNA"
            },
            {
                "informacionTitulo": "5.3 ASEH INFORMES GESTIÓN FINANCIERA"
            }
        ]

    },
    //6.-normas_lgcg
    {
        "titulo": "6.- NORMAS, LGCG",
        "subtitulo": [
            {
                "informacionTitulo": "6.1 Publicaciones Trimestrales"
            },
            {
                "informacionTitulo": "6.2 Publicaciones Anuales"
            }
        ]

    },
    //7.-otras_obligaciones_lgcg
    {
        "titulo": "7.- OTRAS OBLIGACIONES L.G.C.G",
        "subtitulo": [
            {
                "informacionTitulo": "7.1 Programas Anual de Evaluaciónes (Metodología e Indicadores de Desempeño)"
            },
            {
                "informacionTitulo": "7.2 Resultado Del Programa Anual de Evaluaciones"
            },
            {
                "informacionTitulo": "7.3 Montos Pagados por Ayudas y Subsidos"
            }
        ]

    },
    //8.-programas_anuales
    {
        "titulo": "8.- PROGRAMAS ANUAL DE ADQUISICIONES, ARRENDAMIENTOS Y SERVICIOS",
        
    },
    //9.-ley de disciplina financiera 
    {
        "titulo": "9.- LEY DE DISCIPLINA FINANCIERA",
        "subtitulo": [
            {
                "informacionTitulo": "9.1 Estado de Situación Financiera Detallado"
            },
            {
                "informacionTitulo": "9.2 Informe Analítico de la Deuda y Otros Pasivos"
            },
            {
                "informacionTitulo": "9.3 Informe Analítico de Obligaciones Diferentes de Financiamientos"
            },
            {
                "informacionTitulo": "9.4 Balance Presupuestario"
            },
            {
                "informacionTitulo": "9.5 Estado Analítico de Ingresos Detallado"
            },
            {
                "informacionTitulo": "9.6 Estado Analítico del Presupuesto de Egresos Detallado C.O.G"
            },
            {
                "informacionTitulo": "9.7 Estado Analítico del Ejercicio del Presupuesto de Egresos Detallado C.A"
            },
            {
                "informacionTitulo": "9.8 Estado Analítico del Ejercicio del Presupuesto de Egresos Detallado C.F"
            },
            {
                "informacionTitulo": "9.9 Estado Analítico del Ejercicio del Presupuesto de Egresos Detallado Clasificación de Servicios Personales por Categoria"
            },
            {
                "informacionTitulo": "9.10 Balance Presupuestario de Recursos Diponibles Negativo 2017 Artículo 6 LDF"
            },
            {
                "informacionTitulo": "9.11 Aumento o Creación del Presupuesto de Egresos Durante el Ejercicio de 2017 Artículo 8 LDF"
            },
            {
                "informacionTitulo": "9.12 Devengados en el Año que Integra el Pasivo Circulante al Cierre del Ejercicio 2017 Artículo 13 LDF"
            },
            {
                "informacionTitulo": "9.13 Ingresos Exedentes Derivados de Ingresos de Libre Diposición 2017 Artículo 14 LDF"
            },
            {
                "informacionTitulo": "9.14 Contratación de Deuda Pública y Obligaciones a Corto Plazo Artículo 25 LDF"
            },
            {
                "informacionTitulo": "9.15 Información Detallada de Contratación de Obligaciones a Corto Plazo Articulo 31 LDF"
            },
            {
                "informacionTitulo": "9.16 Resultados de Ingresos LDF"
            },
            {
                "informacionTitulo": "9.17 Resultados de Egresos LDF"
            },
            {
                "informacionTitulo": "9.18 Proyecciones de Ingresos LDF"
            },
            {
                "informacionTitulo": "9.19 Proyecciones de Egresos LDF"
            },
            {
                "informacionTitulo": "9.20 Informe Sobre Resultados Actuariales LDF"
            },
            {
                "informacionTitulo": "9.21 Guía de Cumplimiento LDF"
            },
            {
                "informacionTitulo": "DICTAMEN DE AUDITORIA EXTERNA 2017"
            },
            {
                "informacionTitulo": "CARTA DE OBSERVACIONES Y RECOMENDACIONES DE AUDITORIA EXTERNA"
            },
            {
                "informacionTitulo": "CUENTA PÚBLICA ANUAL"
            },
            
        ]

    },
    {
        "titulo": "INICIATIVA DE LEY INGRESOS",
        
    },
    {
        "titulo": "LA INFORMACIÓN COMPLEMENTARIA PARA GENERAR LAS CUENTAS NACIONALES Y ATENDER OTROS REQUIRIMIENTOS PROVENIENTES DE ORGANISMOS INTERNACIONALES DE LOS MÉXICANOS ES MIEMBRO",
        
    },
    {
        "titulo": "LEY DE INGRESOS",
        
    },
    {
        "titulo": "LEY FEDERAL DE ENTIDADES PARAESTATALES",
        
    },
    {
        "titulo": "LEY GENERAL DE CONTABILIDAD GUBERNAMENTAL",
        
    },
    {
        "titulo": "MANUAL DE CONTABILIDAD",
        
    },
    {
        "titulo": "NORMA PARA ESTABLECER EL FORMATO PARA LA DIFUSIÓN DE LOS RESULTADOS DE LAS EVALUACIONES DE LOS RECURSOS FEDERALES MINISTRADOS A LAS ENTIDADES FEDERATIVAS",
        
    },
    {
        "titulo": "NORMA PARA ESTABLECER LA ESTRUCTURA DE INFORMACIÓN DE MONTOS PAGADOS POR AYUDAS Y SUBSIDIOS",
        
    },
    {
        "titulo": "NORMA PARA ESTABLECER LA ESTRUCTURA DE INFORMACIÓN DEL FORMATO DE PROGRAMAS CON RECURSOS FEDERALES POR ORDEN DE GOBIERNO",
        
    },
    {
        "titulo": "NORMA PARA ESTABLECER LA ESTRUCTURA DEL CALENDARIO DE EGRESOS BASE MENSUAL",
        
    },
    {
        "titulo": "NORMA PARA ESTABLECER LA ESTRUCTURA DEL CALENDARIO DE INGRESOS BASE MENSUALS",
        
    },
    {
        "titulo": "NORMA PARA LA DIFUSIÓN A LA CIUDADANÍA DE LA LEY DE INGRESOS Y DEL PRESUPUESTO DE EGRESOS",
        
    },
    {
        "titulo": "NORMAS PARA ESTABLECER LA ESTRUCTURA DE INFORMACIÓN DEL FORMATO DEL EJERCICIO Y DESTINO DE GASTO FEDERALIZADO Y REINTEGROS",
        
    },
    {
        "titulo": "PAAAS",
        
    },
    {
        "titulo": "PRESUPUESTO DE EGRESOS",
        
    },
    {
        "titulo": "PRESUPUESTO DE INGRESOS",
        
    },
    {
        "titulo": "PROYECTO DE PRESUPUESTO DE EGRESOS",
        
    },
    {
        "titulo": "REMITIR A LA SHCP A TRAVÉS DEL SISTEMA DE INFORMACIÓN A QUE SE REFIERE EL ARTÍCULO 85 DE LA LFPRH LA INFORMACIÓN SOBRE EJERCICIO Y DESTINO DE GASTOS FEDERALES",
        
    },
    {
        "titulo": "APLICACION DE RECURSOS FEDERALES PARA EL FONDO DE APORTACONES PARA LA INFRAESTRUCTURA SOCIAL FAIS",
        
    },
    {
        "titulo": "APORTACIONES FEDERALES A FAETA",
        
    },
    {
        "titulo": "APORTACIONES FEDERALES A FONDO DE AYUDA PARA SEGURIDAD PUBLICA",
        
    },
    {
        "titulo": "APORTACIONES FEDERALES A FORTAMUN",
        
    },
    {
        "titulo": "APORTACIONES FEDERALES EN MATERIA DE SALUD",
        
    },
    {
        "titulo": "APORTACIONES FEDERALES A FONDO DE AYUDA PARA SEGURIDAD PUBLICA",
        
    },
    {
        "titulo": "APROBACION DE LA LEY DE INGRESOS",
        
    },
    {
        "titulo": "GASTO FEDERALIZADO Y REINTEGROS ANUAL",
        
    },
    {
        "titulo": "GASTO FEDERALIZADO Y REINTEGROS TRIMESTRAL",
        
    },
    {
        "titulo": "INICIATIVA_DE_LEY_DE_INGRESOS",
        
    },
   {
        "titulo": "LEY.",
   },
    {
        "titulo": "NORMA PARA ESTABLECER EL FORMATO PARA LA DIFUSION DE LOS RESULTADOS DE LAS EVALUACIONES DE LOS RECURSOS FEDERALES MINISTRADOS A LAS ENTIDADES FEDERATIVAS",
        
    },
    {
        "titulo": "NORMA PARA ESTABLECER LA ESTRUCTURA DEL CALENDARIO DE INGRESOS BASE MENSUAL",
        
    },
    {
        "titulo": "NORMA PARA LA DIFUSION A LA CIUDADANIA DE LA LEY DE INGRESOS Y DEL PRESUPUESTO DE EGRESOS",
        
    },
    {
        "titulo": "OBLIGACIONES QUE SE PAGAN O GARANTIZAN CON RECURSOS FEDERALES",
        
    },
    {
        "titulo": "PRESUPUESTO DE EGRESOS APROBADO",
        
    },
    {
        "titulo": "PROGRAMAS EN QUE CONCURRAN RECURSOS FEDERALES",
        
    },
    {
        "titulo": "PROYECTO DE PRESUPUESTO DE EGRESOS.",
        
    },
    {
        "titulo": "RELACION DE CUENTAS BANCARIAS PRODUCTIVAS ESPECIFICAS EN LAS QUE SE DEPOSITAN Y MINISTRAN REC FEDERALES.",
        
    },
    {
        "titulo": "Programa Anual de Evaluación",
        /* "titulo": "LEY DE INGRESOS DEL ESTADO EJERCICIO FISCAL",*/
        
    }
    
];